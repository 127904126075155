const initialState = {
  routelist: null,
  userlist: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "ROUTE_LIST":
      return { ...state, routelist: action.payload.route };
    case "USER_LIST":
      return { ...state, userlist: action.payload.user };
    default:
      return state;
  }
};
