const INITIAL_STATE = {
  list: null,
  countlist: null,
  outbound: null,
  countoutbound: null,
  misc: null,
  countmisc: null,
  excellist: null,
  tofilterdata: null,
  archivedInboundlist: null,
  archivedOutboundlist: null,
  archivedMisclist: null,
  archivedInboundCount: null,
  archivedOutboundCount: null,
  archivedMiscCount: null,
  archivesocket: []
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "REPORT_LIST": {
      return {
        ...state,
        list: action.payload.call_details,
        countlist: action.payload.total_data
      };
    }
    case "OUTBOUND_REPORT_LIST": {
      return {
        ...state,
        outbound: action.payload.call_details,
        countoutbound: action.payload.total_data
      };
    }
    case "MISC_REPORT_LIST": {
      return {
        ...state,
        misc: action.payload.call_details,
        countmisc: action.payload.total_data
      };
    }
    case "TO_FILTERING_DATA": {
      return {
        ...state,
        tofilterdata: action.payload
      };
    }
    case "ARCHIVE_INBOUND_LIST": {
      return {
        ...state,
        archivedInboundlist: action.payload.status,
        archivedInboundCount: action.payload.count
      };
    }
    case "ARCHIVE_OUTBOUND_LIST": {
      return {
        ...state,
        archivedOutboundlist: action.payload.status,
        archivedOutboundCount: action.payload.count
      };
    }
    case "ARCHIVE_MISC_LIST": {
      return {
        ...state,
        archivedMisclist: action.payload.status,
        archivedMiscCount: action.payload.count
      };
    }
    case "ARCHIVE_SOCKET": {
      return {
        ...state,
        archivesocket: [...state.archivesocket, action.payload]
      };
    }
    default: {
      return state;
    }
  }
}
