import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import PageLoading from "./components/PageLoading";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
//import swDev from "./swDev";
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const history = createBrowserHistory({ basename: baseUrl });

ReactDOM.render(
  //<React.StrictMode>
  <Provider store={store}>
    <Router history={history}>
      <ConfigProvider locale={enUS}>
        <App />
        <PageLoading />
      </ConfigProvider>
    </Router>
  </Provider>,
  //</React.StrictMode>
  document.getElementById("root")
);
//swDev();
