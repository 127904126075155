import _ from "lodash";
const INITIAL_STATE = {
  list: null,
  single: null,
  callerlist: null,
  countlist: null,
  destination_data: null
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "EXTENSION_LIST": {
      return {
        ...state,
        list: action.payload.extension,
        countlist: action.payload.total_data
      };
    }
    case "SEARCH_EXTENSION_LIST": {
      return {
        ...state,
        list: action.payload.extension,
        countlist: action.payload.total_data
      };
    }
    case "INDIVIDUAL_EXTENSION": {
      return { ...state, single: action.payload };
    }
    case "CALLER_LIST": {
      return { ...state, callerlist: action.payload };
    }
    case "UPDATE_EXTENSION_PASSWORD": {
      return {
        ...state,
        list: _.unionBy([action.payload], state.list, "id")
      };
    }
    case "DESTINATION_DATA": {
      return {
        ...state,
        destination_data: action.payload
      };
    }
    case "ADD_EXTENSION": {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
